var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        title: _vm.title,
        "max-width": "330px",
        persistent: "",
        cardTextClass: "text-center",
        confirmButton: _vm.computedConfirmButton,
        cancelButton: _vm.computedCancelButton
      },
      on: { cancel: _vm.cancel },
      model: {
        value: _vm.isModalOpen,
        callback: function($$v) {
          _vm.isModalOpen = $$v
        },
        expression: "isModalOpen"
      }
    },
    [[_c("v-progress-circular", { attrs: { indeterminate: "" } })]],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }