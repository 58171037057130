<template>
    <BaseModal ref="modal"
        @cancel="cancel" @confirm="triggerSubmit"
        v-model="isModalOpen"
        :title="title"
        :confirmButton="computedConfirmButton"
        :cancelButton="computedCancelButton"
        max-width="330px"
        persistent
    >
        <PrizeCategoryForm
            ref="prize_category_form"
            v-if="!loading"
            v-model="prizeCategory"
            :isFormValid.sync="isFormValid"
            :formType="'CREATE'"
            @submit="confirm"
            @triggerSubmit="triggerSubmit"
            :loading="loading"
        />
        <span v-else>{{ $t('forms.wait') }}</span>
    </BaseModal>
</template>

<script>
import PrizeCategoryForm from '@/components/forms/PrizeCategoryForm'
import BaseModal from '@/components/modals/BaseModal'
import PrizeCategory from '@/models/PrizeCategory'
import BaseModalMixin from '../BaseModalMixin'

export default {
    name: 'PrizeCategoriesCreateModal',
    mixins: [ BaseModalMixin ],
    components: { BaseModal, PrizeCategoryForm },
    props: {
        prizeCategory: {
            type: PrizeCategory,
            required: true,
        },
    },
    data: vm => {
        return {
            isFormValid: false,
            title: vm.$t('views.prizeCategory.Create.title'),
            loading: false,
        }
    },
    methods: {
        triggerSubmit() {
            this.$refs.prize_category_form.submit()
        },
        async beforeConfirm(prizeCategory) {
            if (!this.isFormValid)
                return false

            this.loading = true

            let errType
            const response = await prizeCategory.create()
                .catch(err => {
                    if (err.message == 'Network Error') {
                        errType = 'network_error'
                        return 
                    }

                    if (err.response.data.type) {
                        errType = err.response.data.type
                        return
                    }

                    errType = 'error'
                })

            this.loading = false

            if (errType) {
                const definedErrors = [
                    'network_error'
                ]
                errType = definedErrors.includes(errType) ? errType : 'error'
                await new Promise((resolve) => {
                    this.$bus.$emit('alert', this.$t(`views.prizeCategory.Create.alerts.${errType}`), 'error', resolve)
                })
                this.$router.push({ name: 'prizeCategory.list'})
                return false
            }

            this.resolveValue = this.prizeCategory
            return true
        },
    },
    computed: {
        computedConfirmButton() {
            return {
                label: this.$t('actions.save'),
                disabled: !this.isFormValid,
                loading: this.loading,
            }
        },
        computedCancelButton() {
            return {
                disabled: this.loading,
            }
        },
    },
}
</script>