<template>
    <v-container fluid class="pt-0">
        <CreateModal  ref="create_modal"  :prizeCategory="selectedPrizeCategory" />
        <DetailsModal ref="details_modal" :prizeCategory="selectedPrizeCategory" />
        <EditModal    ref="edit_modal"    :prizeCategory="selectedPrizeCategory" />
        <LoadingModal ref="loading_modal" />

        <span class="pl-5 pl-md-12 section-title">{{ $t('views.prizeCategory.title') }}</span>
        <v-alert v-if="hasError" type="error" class="mt-4 mx-5 mt-md-6 mx-md-12">
            {{ $t('errors.generic') }}
        </v-alert>
        <PrizeCategoryTable
            v-else
            ref="table"
            class="my-4 mx-2 mx-md-10"
            :fetchFunction="fetchFunction"
            @create="openCreate" 
            @details="openDetails" 
            @edit="openEdit"
            @delete="handleDelete"
        />
    </v-container>
</template>

<script>
import DetailsModal         from '@/components/modals/prizeCategories/DetailsModal'
import CreateModal          from '@/components/modals/prizeCategories/CreateModal'
import EditModal            from '@/components/modals/prizeCategories/EditModal'
import LoadingModal         from '@/components/modals/LoadingModal'
import PrizeCategoryTable   from '@/components/tables/PrizeCategoryTable'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import PrizeCategory        from '@/models/PrizeCategory'

export default {
    data: () => ({
        selectedPrizeCategory: new PrizeCategory,
        hasError             : false,
        loading              : false,
    }),
    mixins: [ HasErrorHandlerMixin ],
    components: { PrizeCategoryTable, CreateModal, DetailsModal, EditModal, LoadingModal },
    methods: {
        async openCreate() {
            this.selectedPrizeCategory = new PrizeCategory()
            let r = await this.$refs.create_modal.open()

            if (r) {
                await new Promise((resolve) => {
                    this.$bus.$emit('alert', this.$t('views.prizeCategory.Create.alerts.success'), 'success', resolve)
                })
                await this.refreshTable()
            }
        },
        async openDetails(item) {
            this.selectedPrizeCategory = new PrizeCategory(item)
            this.$refs.details_modal.open()
        },
        async openEdit(item) {
            // Precisa criar uma nova instância
            this.selectedPrizeCategory = new PrizeCategory(item)
            let r = await this.$refs.edit_modal.open()

            if (r) {
                await new Promise((resolve) => {
                    this.$bus.$emit('alert', this.$t('views.prizeCategory.Edit.alerts.success'), 'success', resolve)
                })
                await this.refreshTable()
            }
        },
        async handleDelete(prizeCategory) {
            this.$refs.loading_modal.open()

            await prizeCategory.delete()
                .catch(this.preErrorHandler)

            if (this.hasError) {
                this.hasError = false
                return
            }

            this.$refs.loading_modal.close()

            this.$bus.$emit('message', this.$t('views.prizeCategory.List.alerts.delete_success'), 'success')
            await this.refreshTable() 
        },
        async refreshTable() {
            await this.$refs.table.refresh()
        },
        async fetchFunction(pagination) {
            this.hasError = false
            let response = await PrizeCategory.list(pagination)
                .catch(this.preErrorHandler)
            return await PrizeCategory.hydrateWithCallback(this.$lodash.get(response, 'data', []), item => item)
        },
         preErrorHandler(e) {
            this.hasError = true
            this.errorHandler(e)
        },
    },
}
</script>