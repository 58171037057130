<template>
    <BaseModal ref="modal"
        @cancel="cancel"
        v-model="isModalOpen"
        :title="title"
        max-width="330px"
        persistent
        cardTextClass="text-center"
        :confirmButton="computedConfirmButton"
        :cancelButton="computedCancelButton"
    >
        <template>
            <v-progress-circular indeterminate />
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal'
import BaseModalMixin from './BaseModalMixin'

export default {
    name: 'LoadingModal',
    mixins: [ BaseModalMixin ],
    components: { BaseModal },
    data: vm => {
        return {
            title: vm.$t('modals.LoadingModal.wait'),
        }
    },
    computed: {
        computedConfirmButton() {
            return {
                hide: true
            }
        },
        computedCancelButton() {
            return {
                hide: true
            }
        }
    }
}
</script>