<template>
    <BaseModal ref="modal"
        @cancel="cancel" @confirm="confirm"
        v-model="isModalOpen"
        :title="title"
        :confirmButton="computedConfirmButton"
        :cancelButton="computedCancelButton"
        max-width="330px"
    >
        <v-row>
            <v-col cols="12">
                <cp-text-field
                    ref="name_field"
                    :label="$t('forms.PrizeCategoryForm.name.label')"
                    readonly type="text"
                    v-model="prizeCategory.name"
                 />
            </v-col>
        </v-row>
    </BaseModal>
</template>

<script>
import BaseModal from '@/components/modals/BaseModal'
import PrizeCategory from '@/models/PrizeCategory'
import BaseModalMixin from '../BaseModalMixin'

export default {
    name: 'PrizeCategoriesDetailsModal',
    mixins: [ BaseModalMixin ],
    components: { BaseModal },
    props: {
        prizeCategory: {
            type: PrizeCategory,
            required: true,
        },
    },
    data: vm => {
        return {
            title: vm.$t('views.prizeCategory.Details.title'),
        }
    },
    computed: {
        computedConfirmButton() {
            return {
                label: this.$t('actions.close'),
            }
        },
        computedCancelButton() {
            return {
                hide: true,
            }
        },
    },
}
</script>