<template>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <v-row>
            <v-col cols="12">
                <cp-text-field
                    ref="name_field"
                    :label="$t('forms.PrizeCategoryForm.name.label')"
                    :placeholder="$t('forms.PrizeCategoryForm.name.placeholder')"
                    required type="text"
                    :error-messages="getErrorMessages('name')"
                    v-model="value.name"
                    @input="clearErrorMessages('name')"
                    autofocus
                 />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import prizeCategory from '@/models/PrizeCategory'
import FormMixin from '@/mixins/FormMixin'

export default {
    name: 'PrizeCategoryForm',
    mixins: [ FormMixin(prizeCategory, 'name_field') ],
}
</script>