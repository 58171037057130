var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        title: _vm.title,
        confirmButton: _vm.computedConfirmButton,
        cancelButton: _vm.computedCancelButton,
        "max-width": "330px",
        persistent: ""
      },
      on: { cancel: _vm.cancel, confirm: _vm.triggerSubmit },
      model: {
        value: _vm.isModalOpen,
        callback: function($$v) {
          _vm.isModalOpen = $$v
        },
        expression: "isModalOpen"
      }
    },
    [
      !_vm.loading
        ? _c("PrizeCategoryForm", {
            ref: "prize_category_form",
            attrs: {
              isFormValid: _vm.isFormValid,
              formType: "EDIT",
              loading: _vm.loading
            },
            on: {
              "update:isFormValid": function($event) {
                _vm.isFormValid = $event
              },
              "update:is-form-valid": function($event) {
                _vm.isFormValid = $event
              },
              submit: _vm.confirm,
              triggerSubmit: _vm.triggerSubmit
            },
            model: {
              value: _vm.prizeCategory,
              callback: function($$v) {
                _vm.prizeCategory = $$v
              },
              expression: "prizeCategory"
            }
          })
        : _c("span", [_vm._v(_vm._s(_vm.$t("forms.wait")))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }